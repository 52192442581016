import React, { useEffect } from 'react';
import { CardType, CarouselDisplayStyle, useGlobalContext, parseBoolean, hasFeatureFlag, useShortlist, FeatureFlagEnum, getTypedLastSearch, } from '@honeycomb/data';
import { Carousel as CoreCarousel, CarouselItem, Styler, LazyLoadComponent, CarouselViewport, useDisclosure, Button, } from '@honeycomb/ui-core';
import { pushPromotedDeals } from '@honeycomb/tracking';
import dynamic from 'next/dynamic';
import { dealSelectedRouting } from '@honeycomb/routing';
import { useRootComponent } from '../RootComponentContext';
import { DealCard } from '../DealCard/DealCard';
import { PriceBlockCard } from '../PriceBlockCard/PriceBlockCard';
import { MerchandiseCard } from '../MerchandiseCard';
import { usePersonalisedProduct } from '../PersonalisedProductContext';
import { CAROUSEL_THEME } from '../../../types/carousel-types';
import { PersonalisedProductCard } from '../PersonalisedProductCard';
import { PersonalisedProductsLoading } from '../PersonalisedProductsLoading';
const DestinationCardDialog = dynamic(() => import('../DestinationCardDialog').then((module) => module.DestinationCardDialog), { ssr: false });
const TripAdvisorReviewsDialog = dynamic(() => import('../../tripadvisor/TripAdvisorReviewsDialog').then((module) => module.TripAdvisorReviewsDialog), { ssr: false });
const HotelProductCard = dynamic(() => import('../HotelProductCard').then((module) => module.HotelProductCard), { ssr: false });
const DestinationCard = dynamic(() => import('../DestinationCard').then((module) => module.DestinationCard), { ssr: false });
const GenericCard = dynamic(() => import('../GenericCard').then((module) => module.GenericCard), {
    ssr: false,
});
export function Carousel(props) {
    var _a, _b, _c, _d;
    const { resourceStrings: { VIEWMORE }, configSettings: { HCPersonalisedProductCount, EnableShortlist }, } = useGlobalContext();
    const { carousel, tabIndex, moreLinkText, zoneIds, border } = props;
    const { slides } = carousel;
    const [dialogOpen, { open: handleOpenDialog, close: handleCloseDialog }] = useDisclosure(false);
    const [dialogExited, setDialogExited] = React.useState(true);
    const [selectedCard, setSelectedCard] = React.useState(null);
    const { carouselDisplayStyle, hasRepeatingBackgroundImage } = useRootComponent();
    const fullWidth = carouselDisplayStyle === CarouselDisplayStyle.FullWidth;
    const handleOpenCardDialog = (card) => {
        setSelectedCard(card);
        setDialogExited(false);
        handleOpenDialog();
    };
    const hasDestinationCards = slides.some((slide) => slide.__typename === 'DestinationCard');
    const hasHotelCards = slides.some((slide) => slide.__typename === 'HotelCard');
    const { hotels, isPersonalisedProduct, loading } = usePersonalisedProduct();
    const productCount = Number(HCPersonalisedProductCount);
    const hasPersonalisedHotelCard = hotels && hotels.length === productCount;
    useEffect(() => {
        const dealCards = slides.filter((slide) => slide.__typename === 'DealCard');
        if (dealCards.length === 0)
            return;
        pushPromotedDeals(dealCards);
    }, [slides]);
    const handleViewMore = () => {
        const searchParameters = getTypedLastSearch();
        if (searchParameters)
            dealSelectedRouting({ searchParameters });
    };
    const shortlistEnabled = parseBoolean(EnableShortlist) || hasFeatureFlag(FeatureFlagEnum.SHORTLIST);
    const getShortlistResults = useShortlist([], !shortlistEnabled);
    const estabsInShortlist = (_d = (_c = (_b = (_a = getShortlistResults === null || getShortlistResults === void 0 ? void 0 : getShortlistResults.data) === null || _a === void 0 ? void 0 : _a.getShortlist) === null || _b === void 0 ? void 0 : _b.hotels) === null || _c === void 0 ? void 0 : _c.map((hotel) => hotel.estabId.toString())) !== null && _d !== void 0 ? _d : [];
    if (loading) {
        return React.createElement(PersonalisedProductsLoading, null);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(CoreCarousel, { visibleItems: CAROUSEL_THEME.visibleItems[carouselDisplayStyle], spacing: CAROUSEL_THEME.spacing[carouselDisplayStyle], overflow: fullWidth ? 'hidden' : 'visible', color: hasRepeatingBackgroundImage ? 'secondary' : 'primary' },
            React.createElement("div", null,
                isPersonalisedProduct && (hotels === null || hotels === void 0 ? void 0 : hotels.length) ? (React.createElement(CarouselViewport, { component: "section", px: 0 }, hotels === null || hotels === void 0 ? void 0 : hotels.map((h) => {
                    return (React.createElement(CarouselItem, { key: h.hotel.estabId.toString() },
                        React.createElement(PersonalisedProductCard, { isInShortlist: estabsInShortlist === null || estabsInShortlist === void 0 ? void 0 : estabsInShortlist.includes(h.estabId), onReviewOpen: handleOpenCardDialog, searchResult: h })));
                }))) : (React.createElement(CarouselViewport, null, slides.map((slide, index) => {
                    let { cardType } = slide;
                    if (slide.__typename === 'Card')
                        cardType = slide.type;
                    else if (slide.__typename === 'MerchandiseCard')
                        cardType = CardType.Merchandise;
                    const key = `${slide.__typename}-slide-${index.toString()}`;
                    let card;
                    switch (cardType) {
                        case CardType.Hotel: {
                            card = (React.createElement(LazyLoadComponent, { intersectionProps: { triggerOnce: true }, child: React.createElement(HotelProductCard, { onReviewOpen: handleOpenCardDialog, card: slide, border: border }) }));
                            break;
                        }
                        case CardType.Destination: {
                            card = (React.createElement(LazyLoadComponent, { intersectionProps: { triggerOnce: true }, child: React.createElement(DestinationCard, { card: slide, onShowMore: handleOpenCardDialog, index: index.toString(), typename: slide === null || slide === void 0 ? void 0 : slide.__typename, tabIndex: tabIndex }) }));
                            break;
                        }
                        case CardType.Deal:
                            card = React.createElement(DealCard, { card: slide });
                            break;
                        case CardType.Priceblock:
                            card = React.createElement(PriceBlockCard, { cards: slide });
                            break;
                        case CardType.Merchandise:
                            card = (React.createElement(MerchandiseCard, { card: slide, moreLinkText: moreLinkText, key: key, carouselDisplayStyle: carouselDisplayStyle, zoneIds: zoneIds }));
                            break;
                        default: {
                            card = (React.createElement(LazyLoadComponent, { intersectionProps: { triggerOnce: true }, child: React.createElement(GenericCard, { card: slide }) }));
                        }
                    }
                    return React.createElement(CarouselItem, { key: key }, card);
                }))),
                hasPersonalisedHotelCard && (React.createElement(Styler, { display: "flex", alignItems: "center", justifyContent: "center", mt: 4 },
                    React.createElement(Button, { onClick: handleViewMore, mt: 4, color: "callToAction" }, VIEWMORE))))),
        (dialogOpen || !dialogExited) && (React.createElement(React.Fragment, null,
            (hasHotelCards || hasPersonalisedHotelCard) && selectedCard && (React.createElement(TripAdvisorReviewsDialog, { open: dialogOpen, tripadvisorFeedback: ((selectedCard === null || selectedCard === void 0 ? void 0 : selectedCard.__typename) === 'HotelCard'
                    ? selectedCard.hotel.tripadvisorFeedback
                    : selectedCard.tripadvisorFeedback), hotelName: (selectedCard === null || selectedCard === void 0 ? void 0 : selectedCard.__typename) === 'HotelCard'
                    ? selectedCard.hotel.name
                    : selectedCard.name, estabId: (selectedCard === null || selectedCard === void 0 ? void 0 : selectedCard.__typename) === 'HotelCard'
                    ? selectedCard.hotel.estabId
                    : selectedCard.estabId, onClose: handleCloseDialog, onExited: () => setDialogExited(true) })),
            hasDestinationCards && (React.createElement(LazyLoadComponent, { intersectionProps: { triggerOnce: true }, child: React.createElement(DestinationCardDialog, { card: selectedCard, open: dialogOpen, onClose: handleCloseDialog, onExited: () => setDialogExited(true) }) }))))));
}
