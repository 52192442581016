import React, { createContext, useState } from 'react';
import { AnywhereSearchResultEnum, getLastViewedEstab, getSearchCount, TripType, useGlobalContext, getSearchResult, getTypedLastSearch, } from '@honeycomb/data';
import { DateTime } from 'luxon';
import { parseBoolean, useMountEffect } from '../../utils';
export const PersonalisedProductContext = createContext({
    hotels: undefined,
    loading: false,
    isPersonalisedProduct: false,
});
export function usePersonalisedProduct() {
    return React.useContext(PersonalisedProductContext);
}
export function PersonalisedProductContextProvider({ alias, children }) {
    var _a;
    const { configSettings: { HCEnablePersonalisedProduct, HCPersonalisedProductCount }, } = useGlobalContext();
    const productCount = Number(HCPersonalisedProductCount);
    const [hotels, setHotels] = useState(undefined);
    useMountEffect(() => {
        var _a, _b;
        if (alias === 'personalisedProductWidget' && parseBoolean(HCEnablePersonalisedProduct)) {
            const searchData = getTypedLastSearch();
            const today = DateTime.fromISO(new Date().toISOString(), { zone: 'UTC' }).startOf('day');
            const startDate = (searchData === null || searchData === void 0 ? void 0 : searchData.startDate) || (searchData === null || searchData === void 0 ? void 0 : searchData.wholeMonthStartDate) || '';
            const lastSearchCount = Number(getSearchCount() || 0);
            if (lastSearchCount >= productCount &&
                startDate &&
                ((_b = (_a = searchData === null || searchData === void 0 ? void 0 : searchData.destinations) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.id) !== AnywhereSearchResultEnum.ANYWHERE_ID &&
                Date.parse(startDate) > Date.parse(today.toString()) &&
                ((searchData === null || searchData === void 0 ? void 0 : searchData.tripType) === TripType.Holiday || (searchData === null || searchData === void 0 ? void 0 : searchData.tripType) === TripType.Hotel)) {
                const searchResult = getSearchResult(searchData.tripStateId || '');
                setHotels(searchResult);
            }
        }
    });
    const estabData = getLastViewedEstab();
    const estabDetails = JSON.parse(estabData || '{}');
    const contextValue = React.useMemo(() => ({
        hotels: hotels === null || hotels === void 0 ? void 0 : hotels.filter((h) => !h.pricingSummary.isSponsored && String(estabDetails === null || estabDetails === void 0 ? void 0 : estabDetails.estabId) !== h.hotel.estabId).slice(0, productCount),
        loading: false,
        isPersonalisedProduct: alias === 'personalisedProductWidget',
    }), [estabDetails === null || estabDetails === void 0 ? void 0 : estabDetails.estabId, hotels, productCount, alias]);
    if (alias === 'personalisedProductWidget' && ((_a = contextValue.hotels) === null || _a === void 0 ? void 0 : _a.length) !== productCount)
        return null;
    return React.createElement(PersonalisedProductContext.Provider, { value: contextValue }, children);
}
