var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CarouselDisplayStyle, ImageOrigin, useGlobalContext, useImageGroupCard, } from '@honeycomb/data';
import { Anchor, Button, Card, Flag, Image, ImageContent, Observer, Stacker, Styler, TypographyBody, TypographyHero, } from '@honeycomb/ui-core';
import React, { useCallback, useMemo } from 'react';
import { generatePixel } from '../../utils/generatePixel';
import { augmentImageUrl, ImageFormat } from '../../utils/images';
import { mapMerchandiseCardsWithAds } from '../../utils/merchandiseCard';
function getOverlayLogo(overlayLogoUrl, carouselDisplayStyle) {
    if (carouselDisplayStyle === CarouselDisplayStyle.FullWidth) {
        return (React.createElement(Styler, { display: "flex", justifyContent: "center", maw: "100%", mah: { xs: '100px', s: '150px', m: '200px' } },
            React.createElement(Image, { src: overlayLogoUrl, alt: "overlay logo", w: "auto", fit: "contain", pb: 4 })));
    }
    return React.createElement(Image, { src: overlayLogoUrl, alt: "overlay logo", width: 80, pos: "absolute", top: "20px", right: "20px" });
}
export function MerchandiseCard(props) {
    const { resourceStrings: { HC_MERCH_CARD_DEFAULT_BUTTON }, } = useGlobalContext();
    const { card, moreLinkText, carouselDisplayStyle, zoneIds } = props;
    const [cardData, setCardData] = React.useState({});
    const [getPlatform195ImageCardsQuery] = useImageGroupCard();
    const { promotionText1, imageUrl, mobileImageUrl, overlayLogo, overlayText1, overlayText2, overlayText3, imageLink, impressionUrl, clickUrl, viewEventUrl, useExternalImageSource, } = card.zoneId ? cardData : card;
    React.useMemo(() => __awaiter(this, void 0, void 0, function* () {
        if (zoneIds && card.zoneId) {
            try {
                const { data } = yield getPlatform195ImageCardsQuery({ variables: { zoneIds } });
                const values = mapMerchandiseCardsWithAds(card, data === null || data === void 0 ? void 0 : data.getPlatform195ImageCards);
                setCardData(values);
            }
            catch (error) {
                console.error('Error while processing merchandise data', error);
            }
        }
    }), [getPlatform195ImageCardsQuery, zoneIds, card]);
    const needsObserving = !!impressionUrl && !!viewEventUrl;
    const isFullWidth = carouselDisplayStyle === CarouselDisplayStyle.FullWidth;
    const imageSetData = useMemo(() => {
        var _a;
        const imageOrigin = ImageOrigin.Facade;
        const format = ImageFormat.Webp;
        if (!useExternalImageSource && imageUrl) {
            if (isFullWidth) {
                const type = 'fullWidthMerchCard';
                return {
                    l: augmentImageUrl(imageOrigin, imageUrl, type, { breakpoint: 'l', format }),
                    m: augmentImageUrl(imageOrigin, imageUrl, type, { breakpoint: 'm', format }),
                    s: augmentImageUrl(imageOrigin, imageUrl, type, { breakpoint: 's', format }),
                    xs: augmentImageUrl(imageOrigin, imageUrl, type, { breakpoint: 'xs', format }),
                };
            }
            return augmentImageUrl(imageOrigin, imageUrl, 'merchandiseCard');
        }
        return { xs: (_a = mobileImageUrl !== null && mobileImageUrl !== void 0 ? mobileImageUrl : imageUrl) !== null && _a !== void 0 ? _a : '', m: imageUrl !== null && imageUrl !== void 0 ? imageUrl : '' };
    }, [imageUrl, isFullWidth, mobileImageUrl, useExternalImageSource]);
    const cardComponent = React.useMemo(() => {
        var _a, _b;
        return (React.createElement(Card, { adornments: promotionText1 && !isFullWidth
                ? [
                    React.createElement(Flag, { ml: -2, top: "20px", color: "primary", key: "promotion" }, promotionText1),
                ]
                : undefined, h: isFullWidth ? '480px' : '400px', shadow: 1, SurfaceProps: { bg: 'transparent' } },
            React.createElement(Image, { src: imageSetData, withPlaceholder: true, height: "100%", backgroundMask: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 25%, rgba(0, 0, 0, 0.75) 100%)", styleId: isFullWidth ? 'merch-card-full-width' : 'merch-card' },
                overlayLogo && carouselDisplayStyle && getOverlayLogo(overlayLogo, carouselDisplayStyle),
                React.createElement(ImageContent, { c: "text.inverse", display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "center", p: { xs: 8, l: isFullWidth ? 12 : 8 } },
                    React.createElement(Stacker, { spacing: { xs: 5, l: isFullWidth ? 6 : 5 }, alignItems: "center", ta: "center", maw: 900 },
                        React.createElement(Stacker, { spacing: 3 },
                            overlayLogo && isFullWidth && getOverlayLogo(overlayLogo, carouselDisplayStyle),
                            isFullWidth && promotionText1 && (React.createElement(TypographyHero, { size: "s", py: 2, px: 6, ta: "center", bg: "accent.base", br: 22 }, promotionText1))),
                        React.createElement(Anchor, { href: (_a = clickUrl !== null && clickUrl !== void 0 ? clickUrl : imageLink) !== null && _a !== void 0 ? _a : '#', blockLink: true, underline: "none" },
                            React.createElement(TypographyHero, { size: isFullWidth ? '2xl' : 'l' }, overlayText1)),
                        overlayText2 && React.createElement(TypographyBody, { weight: "semiBold" }, overlayText2),
                        overlayText3 && React.createElement(TypographyBody, { weight: "semiBold" }, overlayText3),
                        React.createElement(Button, { color: "white", variant: "outlined", href: (_b = clickUrl !== null && clickUrl !== void 0 ? clickUrl : imageLink) !== null && _b !== void 0 ? _b : undefined }, moreLinkText || HC_MERCH_CARD_DEFAULT_BUTTON))))));
    }, [
        promotionText1,
        isFullWidth,
        imageSetData,
        overlayLogo,
        carouselDisplayStyle,
        overlayText1,
        overlayText2,
        overlayText3,
        clickUrl,
        imageLink,
        moreLinkText,
        HC_MERCH_CARD_DEFAULT_BUTTON,
    ]);
    const onImpression = useCallback(() => impressionUrl && generatePixel(impressionUrl), [impressionUrl]);
    const onViewComplete = useCallback(() => viewEventUrl && generatePixel(viewEventUrl), [viewEventUrl]);
    if (needsObserving) {
        return (React.createElement(Observer, { onImpression: onImpression, onViewComplete: onViewComplete }, cardComponent));
    }
    return cardComponent;
}
