import { useGlobalContext, ImageOrigin, HotelPriceIncludesType, HotelExtraType, TripType, getTypedLastSearch, } from '@honeycomb/data';
import React, { useMemo } from 'react';
import { Card, CardSection, Styler, Image, TypographyHeading, Anchor, Stacker, TypographyBody, LocationIcon, StarRating, InfoItems, InfoItem, KnifeAndForkIcon, CalendarIcon, BusAndCarIcon, PlaneIcon, FlexGrid, Divider, TypographyPrice, Button, Flag, EditIcon, } from '@honeycomb/ui-core';
import { dealSelectedRouting } from '@honeycomb/routing';
import { interjectResourceString, parseBoolean } from '../../utils';
import { augmentImageUrl, getHotelImageBaseUrl } from '../../utils/images';
import { TripadvisorRating } from '../tripadvisor/TripadvisorRating';
import { getFormattedStartDate } from '../../utils/formatDate';
import { StyledMoney } from '../../components';
import { HotelStamp, useHotelFlag } from '../hotel';
import { WishlistHeartButton } from '../wishlist';
export function PersonalisedProductCard(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const { configSettings: { HotelImageCDN, HCEnableTripadvisorReviews }, resourceStrings: { NIGHT, HC_HOTEL_NIGHTS, TRANSFER_INCLUSION_MESSAGE, RETURNFLIGHTS, PRICE_SEARCH_MORE, PERPERSON, VIEWDEAL, TOTALPRICE, TOTALHOLIDAYPRICE, MYB_ACCOUNT_AMENDMENTS_RESULTS_BACK_BUTTON_LABEL, FLYING_FROM, }, } = useGlobalContext();
    const HCEnableTripadvisorReviewsConfig = parseBoolean(HCEnableTripadvisorReviews);
    const { searchResult, onReviewOpen, isInShortlist } = props;
    const { hotel, pricingSummary, flight } = searchResult;
    const { numberOfNights, teaserPrice, pricePerPersonFormatted, callout } = pricingSummary;
    const { name, estabId, geography, starRating, tripadvisorFeedback, tags } = hotel;
    const imageUrl = `${getHotelImageBaseUrl(HotelImageCDN, estabId)}/0`;
    const imageSrc = React.useMemo(() => augmentImageUrl(ImageOrigin.HotelImage, imageUrl, 'portraitCard2'), [imageUrl]);
    const heading = useMemo(() => {
        return (React.createElement(TypographyHeading, { ta: "left", color: "text.heading", size: "s", lineHeight: 1.2 }, name));
    }, [name]);
    const createDataId = (value) => String(value).split(' ').join('-');
    const getHyphenSeparatedName = React.useCallback(createDataId, [createDataId]);
    const search = getTypedLastSearch();
    const { tripType } = search;
    const formattedStartDate = getFormattedStartDate(pricingSummary, search);
    const hasMandatoryTransfer = (_a = pricingSummary.includedExtras) === null || _a === void 0 ? void 0 : _a.some((extras) => extras === HotelExtraType.Transfer);
    const handleDealSelection = (openCalendar) => {
        const searchData = getTypedLastSearch();
        if (searchData)
            dealSelectedRouting({ hotelId: estabId, searchParameters: searchData, openCalendar });
    };
    const hotelFlag = useHotelFlag(false, tags || undefined);
    return (React.createElement(Card, { h: "100%", adornments: hotelFlag ? (React.createElement(Flag, { ml: -2, top: "20px", color: hotelFlag.color, "data-id": `flag_${hotelFlag.label.toLowerCase().replace(' ', '_')}` }, hotelFlag.label)) : undefined },
        React.createElement(CardSection, { pos: "relative", zIndex: 1, p: 0 },
            React.createElement(Image, { withPlaceholder: true, src: imageSrc, alt: name, height: "260px" },
                React.createElement(WishlistHeartButton, { isInShortlist: isInShortlist, estabId: parseInt(estabId !== null && estabId !== void 0 ? estabId : ''), shape: "circle", pos: "absolute", size: "s", top: "16px", right: "16px", zIndex: 1, bg: "common.white" })),
            callout && (callout === null || callout === void 0 ? void 0 : callout.text.length) > 0 && (React.createElement(HotelStamp, { callout: callout, pos: "absolute", bottom: 0, right: 0, mx: 5, mb: 4, "data-id": "stamp", "data-key": estabId }))),
        React.createElement(CardSection, { py: 4, flexGrow: 1 },
            React.createElement(Anchor, { component: "button", onClick: handleDealSelection, underline: "hover" }, heading),
            (geography === null || geography === void 0 ? void 0 : geography.location) && (React.createElement(Stacker, { spacing: 2, direction: "row", alignItems: "center", mt: 2 },
                React.createElement(LocationIcon, { size: "xs" }),
                React.createElement(TypographyBody, { "data-id": `location-${getHyphenSeparatedName(geography.location)}`, px: 1, weight: "semiBold", size: "s" }, geography.location))),
            React.createElement(Stacker, { columnSpacing: 2, direction: "row", alignItems: "center", my: 3 },
                React.createElement(StarRating, { rating: starRating }),
                tripadvisorFeedback && HCEnableTripadvisorReviewsConfig && (React.createElement(TripadvisorRating, { tripadvisorFeedback: tripadvisorFeedback, onClick: () => {
                        onReviewOpen(searchResult.hotel);
                    }, "data-id": `tripadvisorFeedback-${estabId}-${name}` }))),
            React.createElement(Stacker, null,
                React.createElement(Styler, { mt: 1 },
                    React.createElement(InfoItems, null,
                        React.createElement(InfoItem, { icon: React.createElement(CalendarIcon, null), "data-id": "nights", key: HotelPriceIncludesType.NIGHTS_COUNT }, numberOfNights &&
                            `${formattedStartDate} - ${numberOfNights} ${numberOfNights <= 1 ? NIGHT : HC_HOTEL_NIGHTS}`),
                        React.createElement(InfoItem, { icon: React.createElement(EditIcon, null), "data-id": "editdates", key: "editdates" },
                            React.createElement(TypographyBody, { onClick: () => handleDealSelection(true), button: true, underlined: true }, MYB_ACCOUNT_AMENDMENTS_RESULTS_BACK_BUTTON_LABEL)),
                        pricingSummary.boardTypeDescription && (React.createElement(InfoItem, { icon: React.createElement(KnifeAndForkIcon, null), "data-id": "room_description", key: HotelPriceIncludesType.BOARD_BASIS }, pricingSummary.boardTypeDescription)),
                        tripType === TripType.Holiday && (React.createElement(InfoItem, { icon: React.createElement(PlaneIcon, null), key: HotelPriceIncludesType.MANDATORY_TRANSFER }, ((_e = (_d = (_c = (_b = flight === null || flight === void 0 ? void 0 : flight.outboundLeg) === null || _b === void 0 ? void 0 : _b.segments) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.departureAirport) === null || _e === void 0 ? void 0 : _e.title)
                            ? interjectResourceString(FLYING_FROM, (_j = (_h = (_g = (_f = flight === null || flight === void 0 ? void 0 : flight.outboundLeg) === null || _f === void 0 ? void 0 : _f.segments) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.departureAirport) === null || _j === void 0 ? void 0 : _j.title)
                            : RETURNFLIGHTS)),
                        hasMandatoryTransfer && (React.createElement(InfoItem, { icon: React.createElement(BusAndCarIcon, null), key: HotelPriceIncludesType.MANDATORY_TRANSFER }, TRANSFER_INCLUSION_MESSAGE)))))),
        React.createElement(CardSection, { pt: 0 },
            React.createElement(Divider, { mb: 4 }),
            React.createElement(FlexGrid, { container: true, alignItems: "center" },
                React.createElement(FlexGrid, { xs: "fill" },
                    React.createElement(Styler, { h: 80, display: "flex", flexDirection: "column", justifyContent: "center" }, teaserPrice && teaserPrice.value > 0 ? (React.createElement(React.Fragment, null,
                        React.createElement(TypographyBody, { size: "xs", component: "div" }, tripType === TripType.Holiday ? TOTALHOLIDAYPRICE : TOTALPRICE),
                        React.createElement(TypographyPrice, { size: "l", component: "div" },
                            React.createElement(StyledMoney, { parts: (_k = teaserPrice === null || teaserPrice === void 0 ? void 0 : teaserPrice.parts) !== null && _k !== void 0 ? _k : [], formatted: (teaserPrice === null || teaserPrice === void 0 ? void 0 : teaserPrice.formatted) || '' })),
                        React.createElement(TypographyBody, { size: "xs", component: "div" },
                            PERPERSON,
                            " ",
                            pricePerPersonFormatted))) : (React.createElement(TypographyBody, { weight: "semiBold", component: "div" }, PRICE_SEARCH_MORE)))),
                React.createElement(FlexGrid, { xs: "hold" },
                    React.createElement(Button, { onClick: () => handleDealSelection(false), color: "callToAction", fullWidth: true }, VIEWDEAL))))));
}
